<template>
  <div>
    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-3 pt-4"
          >
            <h1 class="color-sec">
              Resultados
            </h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Resultados
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-tabs mb-4">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'resultados' }"
                    class="nav-link chart-tab "
                    aria-selected="false"
                  >
                    Provas
                  </router-link>
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'resultados-turma' }"
                    class="nav-link chart-tab "
                    aria-selected="false"
                  >
                    Turmas
                  </router-link>
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'resultados-aluno-lista' }"
                    class="nav-link chart-tab active"
                    aria-selected="false"
                  >
                    Alunos
                  </router-link>
                </li>
              </ul>
            </div>

            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input id="search" type="search" class="form-control" />
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-3 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button class="btn btn-classic">
                        <i class="fa fa-print" /> IMPRIMIR
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="block__classic table table__results table-striped table-borderless table-hover text-center"
              >
                <thead>
                  <tr>
                    <th class="text-left pl-4" scope="col">Ano</th>
                    <th scope="col">Turma</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Média por Prova</th>
                    <th scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in this.alunos" :key="item.id">
                    <td
                      class="text-left pl-4"
                      v-html="item.turma.anoEnsino.titulo"
                    ></td>
                    <td class="text-center" v-html="item.turma.titulo"></td>
                    <td class="text-center" v-html="item.nome"></td>
                    <td class="text-center" v-html="`<strong>${mediaPorProva(item)}<strong>`"></td>
                    <td>
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="showResult(item.id)"
                      >
                        <i class="far fa-eye" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import moment from "moment";
// import Navigator from '../../../components/resultados/ResultadosNavigator.vue';

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    // Navigator
  },
  data() {
    return {
      showLeftMenu: true,
      alunos: [],
    };
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadAlunos();
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
      } else {
        return "-";
      }
    },
  },
  methods: {
    showResult(idaluno) {
      this.$router.push("resultados-aluno-prova/" + idaluno);
    },
    loadAlunos() {
      this.$api
        .get("alunos")
        .then((response) => {
          response.data.forEach((res) => {
            if (res.turma.trashAt == null) {
              this.alunos.push(res);
            }
          });
          // this.alunos = response.data;
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    mediaPorProva(item) {
      const provasFinalizadas = item.provasAluno.filter(prova => prova.finalizadaEm && prova.notaAlcancada !== null)
      if(!provasFinalizadas.length){
        return 'Não Disponível'
      }
      let somaNotas = 0;
      provasFinalizadas.forEach(prova => {
        somaNotas += (prova.notaAlcancada / prova.prova.notaMaxima);
      })
      let mediaNotas = somaNotas / provasFinalizadas.length * 10;
      return mediaNotas.toFixed(2);
    }
  },
};
</script>

<style></style>
